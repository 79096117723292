import CoreButton, {CoreButtonProps} from '@bishop/core-ui/core-button'

export type FilledButtonProps = CoreButtonProps

export default function FilledButton({children, ...props}: FilledButtonProps) {
  return (
    <CoreButton disableElevation variant="contained" {...props}>
      {children}
    </CoreButton>
  )
}
