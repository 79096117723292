import LayoutBase, {LayoutBaseProps} from '../layout-base'

export type UnauthenticatedLayoutProps = LayoutBaseProps

function UnauthenticatedLayout({
  children,
  ...props
}: UnauthenticatedLayoutProps) {
  return <LayoutBase {...props}>{children}</LayoutBase>
}

export default UnauthenticatedLayout
